<template>
  <div style="height:78vh">
    <ElTable
      v-if="collection"
      v-bind="$attrs"
      :data="filteredCollection"
      row-key="id"
      style="width: 100%;height:75vh; overflow: scroll;"
    >
      <slot />
    </ElTable>
    <el-table
      v-else
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      v-loading="true"
      style="width: 100%;height:70vh"
    >
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object
    },
    filter: {
      type: Function,
      default: () => true
    }
  },
  data() {
    return {
      collection: null
    };
  },
  created() {
    this.$bind("collection", this.query);
  },
  watch: {
    query() {
      this.$bind("collection", this.query);
    }
  },
  computed: {
    filteredCollection() {
      if (!this.collection) return this.collection;
      return this.collection.filter(row => this.filter(row));
    }
  }
};
</script>
