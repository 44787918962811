import Vue from "vue";

import TimeAgo from "@/components/utils/TimeAgo";
import Navigation from "@/components/utils/Navigation";
import ResourceTable from "@/components/utils/ResourceTable";
import ProjectsTable from "@/components/utils/ProjectsTable";
import ResourceLoader from "@/components/utils/ResourceLoader";
import ResourceSelect from "@/components/utils/ResourceSelect";

const components = {
  TimeAgo,
  Navigation,
  ResourceTable,
  ProjectsTable,
  ResourceLoader,
  ResourceSelect
};

Object.keys(components).forEach(name => {
  Vue.component(name, components[name]);
});
