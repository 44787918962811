export const STATE_PLAN = "plan";
export const STATE_WORK = "work";
export const STATE_DONE = "done";
import keyBy from "lodash/keyBy"

export default {
  data() {
    return {
      user: null,
      role: 3,
      userLoading: true,
      projectStates: {
        [STATE_PLAN]: "Planificare",
        [STATE_WORK]: "In Lucru",
        [STATE_DONE]: "Finalizat"
      }
    };
  },
  methods: {
    addActivity(description, projectId, employeeIds = [], result) {
      this.$firestore()
        .collection("activities")
        .add({
          description,
          projectId,
          employeeIds,
          result,
          createdAt: this.$firestore.FieldValue.serverTimestamp()
        });
    },
    removeActivity(activityId) {
      this.$firestore()
        .collection("activities")
        .doc(activityId)
        .delete();
    },
    async uploadFile(file, path) {
      const ref = this.$storage().ref(path);
      await ref.put(file);
      return ref.getDownloadURL();
    },
    async deleteFileByUrl(url) {
      return this.$storage()
        .refFromURL(url)
        .delete();
    },

    async setUser(user) {
      this.user = user
      const users = await this.$fetch(
        this.$firestore().collection("settings/main/roles")
      );
      const usersById = keyBy(users, 'data.user_id')

      if (usersById[user.uid] ) {
        this.role = usersById[user.uid].data.role
      } else {
        alert('defineste roluri in firebase!')
      }
    }
  }
};
