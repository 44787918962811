import Vue from "vue";

Vue.filter("date", function(value) {
  return Intl.DateTimeFormat("ro-RO").format(value);
});

Vue.filter("date2", function(value) {
  return Intl.DateTimeFormat("us-En").format(value);
});

Vue.filter("number", function(value) {
  return Intl.NumberFormat("ro-RO", { maximumFractionDigits: 3 }).format(value);
});

Vue.filter("capitalize", function(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("daysLeft", function(value) {
  if(value) {
    const t1 = new Date(value)
    const t2 = new Date()
    const x = t1.getDate() - t2.getDate()
    if(x > 0) {
      return x === 1 ? x + ' zi ramasa' : x + ' zile ramase'
    }
    if(x === 0) {
      return 'Cu predare azi !'
    }
  }
});
