<template>
  <el-row v-if="loaded">
    <el-col :span="23">
      <ElMenu
        mode="horizontal"
        :router="true"
        :default-active="$route.query.stage"
        background-color="#3D4852"
        text-color="#fff"
        active-text-color="#FFF382"
      >
        <template v-if="$store.role <= 1">
          <ElMenuItem index="/" class="logo" route="/">ASKA</ElMenuItem>
          <ElMenuItem index="proposal" route="/projects?stage=proposal"
            >Oferte</ElMenuItem
          >
          <ElMenuItem index="pending" route="/projects?stage=pending"
            >Comenzi</ElMenuItem
          >
          <ElMenuItem index="active" route="/projects?stage=active"
            >In lucru</ElMenuItem
          >
          <ElMenuItem
            style="border-right:0.2px solid white"
            index="completed"
            route="/projects?stage=completed"
            >Finalizate</ElMenuItem
          >
        </template>

        <ElSubmenu index="#" v-if="$store.role <= 1">
          <template slot="title">
            <i class="el-icon-user-solid"></i>
            Aviva & Plim.
          </template>
          <ElMenu
            :router="true"
            :default-active="$route.fullPath"
            background-color="#3D4852"
            text-color="#fff"
            active-text-color="#FFF382"
          >
            <ElMenuItem index="/projects?client=1" route="/projects?client=1"
              >Aviva</ElMenuItem
            >
            <ElMenuItem
              index="proposal"
              route="/projects?client=H2mINUlGrofgLfkYdiWj"
              >Plimob</ElMenuItem
            >
          </ElMenu>
        </ElSubmenu>

        <ElMenuItem index="/tasks">
          <i class="el-icon-s-order"></i>
          Sarcini</ElMenuItem
        >
        <ElMenuItem index="/materials">
          <i class="el-icon-s-data"></i>
          Materiale</ElMenuItem
        >

        <ElSubmenu v-if="$store.role <= 1" index="/settings">
          <template slot="title">
            <i class="el-icon-setting"></i>
            Avansat
          </template>

          <ElMenuItem index="/suppliers">
            <i class="el-icon-truck"></i>
            Furnizori</ElMenuItem
          >
          <ElMenuItem index="/materials/categories">
            <i class="el-icon-s-grid"></i>
            Categorii de materiale</ElMenuItem
          >

          <ElMenuItem index="/operations">
            <i class="el-icon-s-operation"></i>
            Operatiuni</ElMenuItem
          >

          <ElMenuItem index="/workstations">
            <i class="el-icon-suitcase"></i>
            Posturi de lucru</ElMenuItem
          >
          <ElMenuItem index="/printers">
            <i class="el-icon-printer"></i>
            Prese de tipar</ElMenuItem
          >
          <ElMenuItem index="/employees">
            <i class="el-icon-user"></i>

            Angajati</ElMenuItem
          >
          <ElMenuItem index="/activities">
            <i class="el-icon-s-claim"></i>
            Activitati</ElMenuItem
          >
          <ElMenuItem index="/clients">
            <i class="el-icon-s-custom"></i>
            Clienti</ElMenuItem
          >
          <ElMenuItem index="/raport">
            <i class="el-icon-data-analysis"></i>
            Raport</ElMenuItem
          >
          <ElMenuItem index="/logout" @click="logout">
            <i class="el-icon-s-promotion"></i>
            Logout</ElMenuItem
          >
        </ElSubmenu>

        <template v-else>
          <ElMenuItem index="/activities">Activitati</ElMenuItem>
          <ElMenuItem index="/logout" @click="logout">Logout</ElMenuItem>
        </template>

        <ElMenuItem index="#">
          <el-button @click="suport = true" icon="el-icon-help">
            Suport
          </el-button>
        </ElMenuItem>

        <div
          v-if="$route.path !== '/materials' && $store.role <= 1"
          class="mt-2"
        >
          <el-button
            v-if="missing"
            type="danger"
            @click="$router.push({ path: '/materials?limit' })"
            icon="el-icon-warning"
          >
            stocuri sub limita ({{ missing }})
          </el-button>
        </div>
      </ElMenu>
    </el-col>

    <el-col style="background:#3D4852;color:white; height:60px;" :span="1">
      <el-badge value="v.1.2" class="item mt-3" type="primary"> </el-badge>
    </el-col>

    <ElDialog :visible.sync="suport" title="Suport" class="flex justify-center">
      <div class="flex flex-center justify-center">
        <div v-if="!iframeLoaded">Loading..</div>
        <iframe
          width="400"
          height="400"
          v-on:load="iframeLoaded = true"
          :src="'https://iframe.appvision.ro?email=' + $store.user.email"
        >
        </iframe>
      </div>
    </ElDialog>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      loaded: false,
      iframeLoaded: false,
      suport: false,
      missing: null,
    };
  },
  async mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 500);
    const materialsRef = this.$firestore().collection("materials");
    //TO DO poate ar trebui facut async
    let min = 0;
    materialsRef.get().then((querySnapshot) => {
      // console.log(min)
      querySnapshot.forEach(function(doc) {
        if (
          doc.data().currentQuantity < doc.data().minimumQuantity &&
          doc.data().minimumQuantity !== 0
        ) {
          min++;
        }
      });
      this.missing = min;
    });
  },
  methods: {
    logout() {
      this.$auth().signOut();
    },
  },
};
</script>
