<template>
  <ElSelect
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    filterable
    clearable
    default-first-option
  >
    <ElOption
      v-for="item in sortedItems"
      :key="item.id"
      :value="item.id"
      :label="item.data.name"
    />
  </ElSelect>
</template>

<script>
import { sortBy } from "lodash";
export default {
  props: ["value", "collectionName"],
  data() {
    return {
      items: null
    };
  },
  created() {
    this.$bind("items", this.$firestore().collection(this.collectionName));
  },
  computed: {
    sortedItems() {
      return sortBy(this.items, item => item.data.name);
    }
  }
};
</script>
