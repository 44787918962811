<template>
  <div v-if="type === 'showDate'">
    {{ dateFriendly }}
  </div>
  <ElTooltip v-else :content="tooltip">
    <span style="cursor: default;">{{ distance }}</span>
  </ElTooltip>
</template>

<script>
import { format, formatDistanceStrict } from "date-fns";
import locale from "date-fns/locale/ro";
import isToday from "date-fns/isToday";

export default {
  props: ["date", "type"],
  data() {
    return {
      base: new Date()
    };
  },
  created() {
    this.interval = setInterval(() => {
      this.base = new Date();
    }, 1000 * 60);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    distance() {
      return (
        "acum " +
        formatDistanceStrict(this.date, this.base, {
          locale
        })
      );
    },
    tooltip() {
      return format(this.date, "dd/MM/yyyy HH:mm");
    },
    dateFriendly() {
      if (isToday(this.date)) {
        return "Azi " + format(this.date, "HH:mm");
      } else {
        return format(this.date, "dd/MM/yyyy HH:mm");
      }
    }
  }
};
</script>
