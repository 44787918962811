import { render, staticRenderFns } from "./TimeAgo.vue?vue&type=template&id=f81d7b12&"
import script from "./TimeAgo.vue?vue&type=script&lang=js&"
export * from "./TimeAgo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports