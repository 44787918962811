<template>
  <div style="height:75vh">
    <div v-if="search.number || search.groupid " class="flex flex-row ml-2">
      Introduceti numar complet
    </div>

    <ElTable
      v-if="curentCollection"
      v-bind="$attrs"
      :data="filteredCollection"
      row-key="id"
      style="width: 100%;height:75vh; overflow: scroll;"
    >
      <slot />
    </ElTable>
    <el-table
      v-else
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      v-loading="true"
      style="width: 100%;height:70vh"
    >
    </el-table>
  </div>
</template>

<script>
  const sortByNumber = (a, b) => {
  if (a.data.number > b.data.number) {
    return -1;
  }
  if (a.data.number < b.data.number) {
    return 1;
  }
  return 0;
};
import FiltersMixin from "@/mixins/FiltersMixin"
export default {
  mixins: [FiltersMixin()],
  props: {
    query: {
      type: Object,
    },
    search: {
      type: Object,
    },
    filter: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      collection: null,
    }
  },
  created() {
    this.$bind("collection", this.query)
  },
  watch: {
    query() {
      this.$bind("collection", this.query)
    },
  },
  computed: {
    curentCollection() {
      return this.collection
    },
    filteredCollection() {
      return this.curentCollection.filter((row) => this.filter(row)).sort(sortByNumber)
    },
  },
}
</script>
