<template>
  <router-view v-if="$store.user" />
  <Auth v-else-if="!$store.user && !$store.userLoading" />
  <div v-else-if="$store.userLoading" class="flex flex-center">
  </div>
</template>

<script>
import Auth from "@/views/Auth.vue";


export default {
  components: {
    Auth
  },
   created() {
    let appLoading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
    });

    this.$auth().onAuthStateChanged(user => {
      this.$store.setUser(user)
      appLoading.close()
      this.$store.userLoading = false;
    });

  }

};
</script>
