import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
// import "firebase/database";
// import "firebase/messaging";
// import "firebase/functions";

firebase.initializeApp({
  apiKey: "AIzaSyDXTbpj7Tr2ZP2FTVX94pqe_V3A3HjEIYo",
  authDomain: "aska-27755.firebaseapp.com",
  databaseURL: "https://aska-27755.firebaseio.com",
  projectId: "aska-27755",
  storageBucket: "aska-27755.appspot.com",
  messagingSenderId: "297812409139"
});

if (process.env.NODE_ENV === "development") {
  // firebase.firestore.setLogLevel("debug");
}

export default firebase;
