export default Vue => {
  const getDocument = snapshot => {
    return {
      id: snapshot.id,
      path: snapshot.ref.path,
      data: snapshot.data()
    };
  };

  const getCollection = snapshot => {
    return snapshot.docs.map(getDocument);
  };

  Vue.prototype.$bind = function(name, ref) {
    if (this.$bindings[name]) {
      this.$unbind(name);
    }

    return new Promise((resolve, reject) => {
      this.$bindings[name] = ref.onSnapshot(snapshot => {
        const result = snapshot.docs
          ? getCollection(snapshot)
          : getDocument(snapshot);

        Vue.set(this, name, result);
        resolve(result);
      }, reject);
    });
  };

  Vue.prototype.$unbind = function(name) {
    if (this.$bindings[name]) {
      this.$bindings[name]();
    }
  };

  Vue.prototype.$fetch = async function(ref) {
    const snapshot = await ref.get();
    return snapshot.docs ? getCollection(snapshot) : getDocument(snapshot);
  };

  Vue.mixin({
    created() {
      this.$bindings = {};
    },
    beforeDestroy() {
      Object.keys(this.$bindings).forEach(name => {
        this.$unbind(name);
      });
    }
  });
};
