<script>
export default {
  props: ["path"],
  data() {
    return {
      data: null
    };
  },
  created() {
    this.bind();
  },
  watch: {
    path() {
      this.bind();
    },
    data() {
      this.$nextTick(() => this.$emit("change", this.data));
    }
  },
  methods: {
    bind() {
      this.$bind("data", this.$firestore().doc(this.path));
    }
  },
  render() {
    return this.data
      ? this.$scopedSlots.default({ resource: this.data })
      : null;
  }
};
</script>
