import Vue from "vue";

Vue.directive("mount", {
  bind(el, binding) {
    binding.value(el);
  }
});

Vue.directive("unmount", {
  unbind(el, binding) {
    binding.value(el);
  }
});
