<template>
  <ElForm class="login-form" @submit.native.prevent="login">
    <div class="flex items-center">
      <img style="width:200px;margin-left:50px" src="../assets/moto.png" />
    </div>
    <ElInput class="mb-2 mt-5" type="email" v-model="email" required />
    <ElInput class="mb-2" type="password" v-model="password" required />
    <ElButton
      class="mb-2"
      type="primary"
      native-type="submit"
      :loading="loading"
      >Login</ElButton
    >
    <div v-if="error">{{ error }}</div>
  </ElForm>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      error: null
    };
  },
  methods: {
    async login() {
      try {
        this.error = null;
        this.loading = true;
        let result = await this.$auth().signInWithEmailAndPassword(
          this.email,
          this.password
        );
        this.$logRocket.identify(result.user.uid, {
          name: result.user.email,
          email: result.user.email,
          subscriptionType: "pro"
        });
        this.$router.push('/')
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
