import App from "@/App";
import "@/assets/tailwind.css";
import "@/components";
import "@/directives";
import "@/filters";
import "@/plugins/element";
import "@/registerServiceWorker";
import firebase from "@/services/firebase";
import router from "@/services/router";
import store from "@/services/store";
import "@/style.css";
import VueFirestore from "@/utils/VueFirestore";
import Vue from "vue";
import "./registerServiceWorker";
import LogRocket from "logrocket";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
LogRocket.init("sz02xq/aska");

Bugsnag.start({
  apiKey: '123fb66b53203687d866727748878140',
  plugins: [new BugsnagPluginVue()]
})

Vue.config.productionTip = false;

Vue.use(VueFirestore);

Vue.prototype.$auth = firebase.auth;
Vue.prototype.$store = new Vue(store);
Vue.prototype.$storage = firebase.storage;
Vue.prototype.$firestore = firebase.firestore;
Vue.prototype.$logRocket = LogRocket;
// de adauga bugsnag !!
const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

new Vue({
  router,
  mixins: [store],
  render: h => h(App)
}).$mount("#app");
