import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/tasks"
    },
    {
      path: "/materials",
      component: () =>
        import(/* webpackChunkName: "materials" */ "@/views/Materials.vue")
    },
    {
      path: "/raport",
      component: () =>
        import(/* webpackChunkName: "materials" */ "@/views/Raport.vue")
    },
    {
      path: "/materials/categories",
      component: () =>
        import(
          /* webpackChunkName: "material-categories" */ "@/views/MaterialCategories.vue"
        )
    },
    {
      path: "/projects",
      component: () =>
        import(/* webpackChunkName: "projects" */ "@/views/Projects.vue")
    },
    {
      path: "/projects/:id",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "project-details" */ "@/views/ProjectDetails.vue"
        )
    },
    {
      path: "/tasks",
      component: () =>
        import(/* webpackChunkName: "tasks" */ "@/views/Tasks.vue")
    },
    {
      path: "/clients",
      component: () =>
        import(/* webpackChunkName: "clients" */ "@/views/Clients.vue")
    },
    {
      path: "/suppliers",
      component: () =>
        import(/* webpackChunkName: "suppliers" */ "@/views/Suppliers.vue")
    },
    {
      path: "/activities",
      component: () =>
        import(/* webpackChunkName: "activities" */ "@/views/Activities.vue")
    },
    {
      path: "/settings",
      component: () =>
        import(/* webpackChunkName: "settings" */ "@/views/Settings.vue")
    },
    {
      path: "/operations",
      component: () =>
        import(/* webpackChunkName: "operations" */ "@/views/Operations.vue")
    },
    {
      path: "/printers",
      component: () =>
        import(/* webpackChunkName: "printers" */ "@/views/Printers.vue")
    },
    {
      path: "/workstations",
      component: () =>
        import(
          /* webpackChunkName: "workstations" */ "@/views/Workstations.vue"
        )
    },
    {
      path: "/employees",
      component: () =>
        import(/* webpackChunkName: "employees" */ "@/views/Employees.vue")
    },
    {
      path: "/print/project-info/:id",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "print-project-info" */ "@/views/PrintProjectInfo.vue"
        )
    },
    {
      path: "/print/project-quote/:id",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "print-project-quote" */ "@/views/PrintProjectQuote.vue"
        )
    }
  ]
});
