import { reduce } from "lodash";

export default () => ({
  data() {
    return {
      filters: {}
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        const newFilters = reduce(this.$route.query, queryToFilters, {});
        this.filters = newFilters;
      }
    },
    filters: {
      deep: true,
      handler(filters) {
        const newQuery = reduce(filters, filtersToQuery, {});
        this.$router.push({ query: newQuery }, () => {});
      }
    }
  },
  computed: {
    hasActiveFilters() {
      return Object.values(this.filters).filter(v => v).length;
    }
  },
  methods: {
    resetFilters() {
      let stage = this.filters.stage;
      this.filters = {};
      this.filters.stage = stage;
    }
  }
});

const queryToFilters = (result, value, key) => {
  if (value === null) {
    result[key] = true;
  } else {
    result[key] = value;
  }
  return result;
};

const filtersToQuery = (result, value, key) => {
  if (value === null) {
    result[key] = null;
  } else if (value === true) {
    result[key] = null;
  } else if (typeof value === "number") {
    result[key] = String(value);
  } else if (typeof value === "string" && value !== "") {
    result[key] = value;
  }
  return result;
};
